@import '@hiredigital/ui/variables';

.parallax {
  // position: absolute;
}

.group {
  position: relative;
}

.smallTriangle {
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100px 0 100px 100px;
  border-color: transparent transparent transparent $primary-100;
  opacity: 0.5;
}

.largeTriangle {
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 200px 0 200px 200px;
  border-color: transparent transparent transparent $primary-100;
  opacity: 0.25;
}

// New

// .leftAngle {
//   position: absolute;
//   left: 0;
//   border-top: 100px solid transparent;
//   border-bottom: 100px solid transparent;
//   border-left: 100px solid $primary-100;
//   opacity: 0.5;
// }

// .leftAngleLarge {
//   position: absolute;
//   left: 0;
//   border-top: 200px solid transparent;
//   border-bottom: 200px solid transparent;
//   border-left: 200px solid $primary-100;
//   opacity: 0.25;
// }

.smallRightTriangle {
  position: absolute;
  top: 200px;
  right: 0;

  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100px 100px 100px 0;
  border-color: transparent $primary-100 transparent transparent;
  opacity: 0.5;
}

.largeRightTriangle {
  position: absolute;
  top: 200px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 200px 200px 200px 0;
  border-color: transparent $primary-100 transparent transparent;
  opacity: 0.25;
}

// .rightAngle {
//   position: absolute;
//   border-top: 100px solid transparent;
//   border-bottom: 100px solid transparent;
//   border-right: 100px solid $primary-100;
//   opacity: 0.25;
// }

// .rightAngleLarge {
//   position: absolute;
//   right: 0;
//   border-top: 200px solid transparent;
//   border-bottom: 200px solid transparent;
//   border-right: 200px solid $primary-100;
//   opacity: 0.25;
// }

// .rightAngleSuperLarge {
//   position: absolute;
//   right: 0;
//   border-top: 400px solid transparent;
//   border-bottom: 400px solid transparent;
//   border-right: 400px solid $primary-100;
//   opacity: 0.25;
// }

// .itemContainer {
//   position: relative;
// }

// .background {
//   background-color: $primary-00;
// }
